import React, {useEffect, useState} from 'react';
import logo from "./logo.png";
import melogo from "./me.svg";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import {Button, Container, Row, Col, Image } from 'react-bootstrap';

import pfpanim from "./tmr.gif";
import pfpanim2 from "./tmr2.gif";
import pfpanim3 from "./tmr3.gif";

import FUEGOmike from "./monkes/FUEGOmike.png";
import Don from "./monkes/0xDon.png";
import ba11en from "./monkes/ba11en.png";
import deebska15 from "./monkes/deebska15.png";
import jebivetar from "./monkes/jebivetar.png";
import Conda from "./monkes/Conda.png";
import CKS from "./monkes/CKS.png";
import Monke from './Components/Monke';

function App() {
const [appClass, setAppClass] = useState("App");
const [location, setLocation] = useState(undefined);



useEffect(() => {
  console.log("location changed", location)
  switch(location)
  {
    case "cardnight":
      setAppClass("App-dojo");
    break;

    case "after":
      setAppClass("App-dojo-after");
    break;

    default:
      setAppClass("App");
    break;
  }
},[location])



  return (
<div className={appClass}>   
      
      <img src={logo} className="App-logo" alt="logo" />
<h4>
<code><span className="strikethru">Mint Date: 29 December 4PM UTC</span> - It's SOLD OUT!</code>
<br/>
<code>Buy on the Secondary Market at <a href="https://magiceden.io/marketplace/monkishrebellion" target="_blank">Magic Eden</a></code>
{/* <code>Mint Date: 29 December 4PM UTC on <a href="https://magiceden.io/launchpad" target="_blank">
      <img src={melogo} width="130" alt="MAGIC EDEN" className="m-2"/></a> launchpad!</code>
      <br/>
      <Button variant="success" size="lg" href="https://magiceden.io/launchpad" target="_blank"><i className="far fa-hand-point-right m-2"></i>Go to Mint Page</Button> */}
</h4>

<code>
<h5><code>Join the rebellion now on <a
                className="App-link m-2"
                href="https://twitter.com/MonkishRebel"
                target="_blank"
                rel="noopener noreferrer"
              >Twitter</a> and <a
              className="App-link-discord m-2"
              href="https://discord.gg/monkish"
              target="_blank"
              rel="noopener noreferrer"
            >Discord</a>!</code></h5>
      <h1>
              <a
                className="App-link m-2"
                href="https://twitter.com/MonkishRebel"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter "></i>
              </a>

              <a
                className="App-link-discord m-2"
                href="https://discord.gg/monkish"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-discord"></i>
              </a>

            </h1>
            </code>
           

      <div className="content">
        
        {location === undefined && (
          <><h3>MONKISH LORE</h3><p>

          In order to understand The Monkish Rebellion, you must first
          understand its' history. Led by Rebel Monk, commander of the Monkish and
          creator of the Alpha Degens; the Monkish were once a band of nomadic steppe
          monkes ruling the plains of ancient Chimpanzia. Fierce, loyal, and
          ruthless, they covered the vast plains by steed; pillaging, raiding, and
          striking fear into neighboring "civilized" societies. Their loyal
          squad of warriors was known as the Alpha Degens, as not only were they
          the world's fiercest fighters; but also supposed gamblers, ponzi
          schemers, and drunkards.
          </p>
          <p align="center" >
            <Button className="m-2" variant="success" size="lg" onClick={() => setLocation("roadmap")}><i className="fas fa-map m-2"></i>Roadmap</Button>
            <Button variant="success" size="lg" onClick={() => setLocation("cardnight")}><i className="fas fa-user-ninja m-2"></i>Enter The Degen Dojo</Button>
            </p></>
        )}

        {location === "cardnight" && (
          <><h3>Inside the Degen Dojo</h3>
          <p>
                    As their gold coffers grew; they became fat, lazy full-blown
                    degenerates, often succumbing to debauchery. In fact, their leader
                    Rebel Monk was killed by his own men after he was found cheating in a
                    game of Go.
                  </p>
                  <p>
                    Ripe for the taking, Emperor Klik of the Normian Dynasty, a cultish
                    religious nation-state, moved in and swiftly conquered the Monkish,
                    taking their riches and banning all degenerate activities. Exiled as evil
                    sinners, the Monkish became outcasts, untouchables. Emperor Klik grew
                    his cult-like kingdom by suppressing knowledge, controlling the
                    narrative and executing all detractors. The Normians would go on to
                    rule Chimpanzia for centuries to come.
                  </p>
                  <p>
                    Fast forward 300 years... a Monkish child was born, supposedly of
                    royal lineage tying back to Rebel Monk. This child, Guerrilla Monk,
                    was groomed by the degen stories of his grandmother, schooling him of the once great
                    Rebel Monk and the Alpha Degens. From the very beginning, it was known that this golden
                    child was a very special Monkish degen. The days turned to nights, weeks to months and months to years.
                    This special monke endured secret Monkish instruction, in hopium for him to lead the Monkish in the future Rebellion.
                    Countless gruelling hours spent in the degen dojo honing his degenerate wizardry.
                    </p>
                    
                  <p align="center">
                    <Button variant="success" size="lg"  onClick={() => setLocation("after")}><i className="fab fa-rebel m-2"></i>The Monkish Rebellion</Button>
                    <Button variant="success" size="lg" onClick={() => setLocation(undefined)} className="m-2"><i className="fas fa-undo m-2"></i>to Garden</Button>
                    </p>
                  </>
        )}

{location === "after" && (
          <><h3>The Rebel Monkes Collection</h3>
          <p>
                    Years before he was said to be "ready"; Guerrilla Monk had developed an underground degen card
                    game at the ripe age of 10. By 15, he had instigated a small gang in a quest
                    to revive the Monkish and the Alpha Degens that had long since been forgotten. By the time he
                    was 22, he had grown his own online black-market empire. The pinnacle of his degen ways 
                    came to a head shortly after his 25th birthday when he successfully
                    launched what is known today simply as The Monkish Rebellion; a decentralized
                    attack on the Normiam Theocracy. 
                  </p>
                  <p>These are the degens of <b>The Monkish Rebellion</b>, coming soon to Solana</p>
                  <p align="center">
<Image src={pfpanim} height="256" className="m-2" roundedCircle />
<Image src={pfpanim2} height="256" className="m-2" roundedCircle />
<Image src={pfpanim3} height="256" className="m-2" roundedCircle />


                  </p>
                  {/* <p>                    Coming soon to Solana
                  </p> */}
                  <p align="center">
                    <Button variant="success" size="lg" onClick={() => setLocation("cardnight")}><i className="fas fa-undo m-2"></i>Back</Button></p>
                  </>
        )}
        
        {location === "roadmap" && (
             <><h3>Monkish Roadmap</h3>
             <p>
             <b>Stage 1 - Artwork:</b> Unique monkey artwork done in-house by degen team of <a href="https://twitter.com/brussalen" target="_blank">ba11en</a> &amp; <a href="https://twitter.com/PeopleWantDucks" target="_blank">Joel</a>. Way too many sleepless nights spent crafting the perfect army to take back Chimpanzia.
             </p><p>
<b>Stage 2 - Recruitment:</b> The Monkish Rebellion begins recruitment initiative on the dark web via Twitter and MSB Discord. Looking for like-minded rebel degen to take down Normians.
</p><p>
<b>Stage 3 - Monkish Takeover:</b> MSB and Monkish join degen forces to form an elite team of degen leaders. Guerrilla recruitment period.
</p><p>
<b>Stage 4 - Monkish Enlistment:</b> Join The Monkish Rebellion in the uprising against  Emperor Klik and the Normian Empire. 1408 Rebel Monkes minting on the <a href="https://magiceden.io/" target="_blank">MagicEden Launchpad</a>. UKAI! UKAI!
</p><p>
<b>Stage 5 - WAR:</b> The war against the Normians begins. The rebellion will be bloody. The Normian way of life cannot persist. Monkish must work together, stay strong and never surrender (sell).
</p><p>
<b>Stage 6 - DEKT Mini Game:</b> The Monkish are known for their cunning degen ways and the leadership is already developing a mini game to help fund the Rebellion. This will fill the coffers of the Monkish Army and MSB Members.
<br/>One DEKT Card NFT will be airdropped for every TWO Monkish rebels you hold in your wallet! Must be delisted. 
<br/>To continue receiving a prorated amount of the transaction fees you must hold 2 Monkish and 1 DEKT card.
<br/>More details on DEKT in our Discord.
</p>
<p>PLay our 3 NEW Mini Games Here <a href="https://www.dekt.life/" target="_blank">Dekt.Life</a></p>
<p>
<b>BIG PICTURE:</b> The Monkish Rebellion is your PFP for our MSB world. By investing in MSB and The Monkish Rebellion, you are joining a proven team with a long-term focus as well as getting early access to our future endeavors. 

                     </p>
                     <p align="center">
                     <Button variant="success" size="lg" onClick={() => setLocation("cardnight")}><i className="fas fa-user-ninja m-2"></i>Enter The Degen Dojo</Button>
                       <Button className="m-2" variant="success" size="lg" onClick={() => setLocation(undefined)}><i className="fas fa-undo m-2"></i>Back</Button>
                     
                     </p></>
)}

      </div>
      
           {!location ? (
              <Container  >
              <Row>
                <Col align="center">
                  <p className="">
                    <h3><code>The Team</code></h3>
                  {/* Another project by the same degen MonkeDAO members who brought you  <a href="https://www.monkeshitbox.xyz" target="_blank" className="App-link">MonkeShitBox</a> */}
                  <code className="teamcode"> Brought to you by <a href="https://monkeshitbox.xyz" target="_blank">Team MSB</a>. 
                  <br/>A team of degen MonkeDAO members committed to creating a unique ecosystem focused on long-term growth and 
                  giving back to their loyal community of degens.</code>
                    </p>
    
                  <Row xs={1} md={2} className="team">
                <Monke
                      role=""
                      name="@FUEGOmike"
                      url="https://twitter.com/McAdamsMike"
                      pfp={FUEGOmike}
                    />
    
                    <Monke
                      role=""
                      name="@0xDon"
                      url="https://twitter.com/maman_eth"
                      pfp={Don}
                    />
    
                    <Monke
                      role=""
                      name="@ba11en"
                      url="https://twitter.com/brussallen"
                      pfp={ba11en}
                    />
    
    <Monke
                      role=""
                      name="@CKS"
                      url="https://twitter.com/helllooworldd"
                      pfp={CKS}
                    />
    
                    <Monke
                      role=""
                      name="@deebska15"
                      url="https://twitter.com/profess1234"
                      pfp={deebska15}
                    />
    
                    <Monke
                      role=""
                      name="@jebivetar"
                      url="https://twitter.com/donutplug69"
                      pfp={jebivetar}
                    />
    
                    <Monke
                      role=""
                      name="@Conda"
                      url="https://twitter.com/bitCompendium"
                      pfp={Conda}
                    />
    
                    
    </Row></Col></Row></Container>
           ) : (
            <><br/><br/></>)}


    </div>
  );
}

export default App;
